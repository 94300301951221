<template>
  <a-drawer
    :destroy-on-close="true"
    :title="isUpdate ? 'SSL 证书编辑' : 'SSL 证书创建'"
    :visible="visible"
    :width="800"
    @close="closeDrawer"
  >
    <a-form-model
      ref="form"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
      :wrapper-col="{
        span: 20
      }"
    >
      <a-form-model-item prop="url">
        <question-icon
          slot="label"
          title="检测 URL"
          description="用于 SSL 证书检测"
        ></question-icon>
        <a-input
          v-model="form.url"
          :disabled="isUpdate"
          :max-length="128"
          placeholder="以 https:// 开头"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item prop="groups">
        <group-select
          v-model="form.groups"
          source-type="ssl_certificate"
        ></group-select>
        <question-icon
          slot="label"
          title="所属群组"
          description="用于分组查询及告警配置"
        ></question-icon>
      </a-form-model-item>
      <a-form-model-item
        :wrapper-col="{ span: 20, offset: 4 }"
        style="margin-bottom: 0"
      >
        <a-space size="middle">
          <a-button :loading="loading" type="primary" @click="ok">
            确定
          </a-button>
          <a-button @click="closeDrawer">取消</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {
  createSSLCertificate,
  getSSLCertificate,
  updateSSLCertificate
} from '@/api/ssl-certificate'
import QuestionIcon from '@/components/icon/QuestionIcon'
import GroupSelect from '@/components/select/GroupSelect'

export default {
  name: 'InputDrawer',
  components: {
    GroupSelect,
    QuestionIcon
  },
  data () {
    return {
      visible: false,
      isUpdate: false,
      form: {
        id: '',
        url: '',
        groups: undefined
      },
      rules: {
        url: [
          {
            message: '请输入 SSL 证书检测 URL',
            required: true,
            trigger: 'blur'
          },
          {
            message: '请输入正确的 URL',
            type: 'url',
            trigger: 'blur'
          }
        ],
        groups: [
          {
            message: '请选择所属群组',
            required: true,
            trigger: 'select'
          }
        ]
      },
      loading: false
    }
  },
  methods: {
    show (id) {
      if (id) {
        this.isUpdate = true
        getSSLCertificate(id)
          .then(res => {
            const data = res.data
            this.form = {
              id: data.id,
              url: data.url,
              groups: data.groups
            }
          })
          .finally(() => {
            this.visible = true
          })
      } else {
        this.isUpdate = false
        this.visible = true
      }
    },
    closeDrawer () {
      this.form = {
        id: '',
        url: '',
        groups: undefined
      }
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          if (this.isUpdate) {
            const params = {}
            if (this.form.groups) { params.group_ids = this.form.groups.map(group => group.id) }
            updateSSLCertificate(this.form.id, params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            const params = {
              url: this.form.url
            }
            if (this.form.groups) { params.group_ids = this.form.groups.map(group => group.id) }
            createSSLCertificate(params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      })
    }
  }
}
</script>
